<template>
  <div class="warp_box">
    <div class="home_info">
      <img class="shrink" src="../assets/img/head_portrait.png" />
      <div class="info_box grow">
        <div class="title">{{ userData.phoneNo }}</div>
        <div class="con">
          <van-icon name="medal" />
          {{
            userData.signStatus == 0
              ? "未签约"
              : userData.signStatus == 1
              ? "已签约"
              : "解约"
          }}
        </div>
      </div>
    </div>
    <div class="info_warp">
      <div class="h_card amoutn">
        <span>总收款（元）</span>
        <p>￥{{ userData.totalAmount == null ? '0' : userData.totalAmount }}</p>
        <span><van-icon name="warning" />数额为您在平台历史已收款的总金额</span>
      </div>
      <ul class="h_card nav_list">
        <li @click="doTask"><van-icon name="send-gift" />任务记录</li>
        <li @click="doRecord"><van-icon name="gold-coin" />收款记录</li>
        <li @click="doCenter"><van-icon name="manager" />个人信息</li>
      </ul>
    </div>
    <!--<div class="blank"></div>
    
    <div class="menu">
      <van-cell-group inset>
        <van-cell title="任务记录" center icon="todo-list" @click="doTask" />
        <van-cell title="收款记录" center icon="gold-coin" />
        <van-cell title="个人信息" center icon="manager" @click="doCenter" />
      </van-cell-group>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      userUuid: "",
      userData: {},
      totalAmount: "",
    };
  },
  components: {},
  mounted() {
    document.title = "圆梦社";
    const userObj = JSON.parse(localStorage.getItem("userObj"));
    this.userUuid = userObj !== null ? userObj.uuid : "";
    this.getUser();
  },
  methods: {
    getUser() {
      this.$axios
        .get(`/api/mobile/user/detail/${this.userUuid}`)
        .then((res) => {
          if (res.data.code === 200) {
            // signStatus 0、未签约 1、已签约 2、解约
            console.log("res.data", res.data);
            this.userData = res.data.data;
            this.totalAmount = "总收款：" + this.userData.totalAmount;
          }
        });
    },
    doCenter() {
      this.$router.push("/info");
    },
    doTask() {
      this.$router.push("/task/list");
    },
    doRecord() {
      this.$router.push("/task/record");
    },
  },
};
</script>
<style scoped>
.warp_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.info_warp {
  position: absolute;
  top: 100px;
  width: calc(100% - 80px);
  left: 0;
  margin: 0 20px;
}
.h_card {
  width: 100%;
  padding: 10px 20px;
}
.amoutn {
  margin-bottom: 15px;
}
.nav_list li {
  border-bottom: 1px dashed #ddd;
  line-height: 50px;
  color: #101010;
}
.nav_list li:last-child {
  border: 0 none;
}
.nav_list li .van-icon {
  color: #165DFF;
  font-size: 22px;
  vertical-align: middle;
  margin-right: 10px;
}
.h_card span {
  display: block;
  color: #ACADAE;
  font-size: 14px;
  line-height: 30px;
}
.h_card p{
  font-size: 32px;
  margin: 0;
  font-weight: 600;
}
.h_card span .van-icon {
  font-size: 16px;
  color: #165DFF;
  vertical-align: middle;
  margin-right: 10px;
}
.home_info{
  width: 100%;
  background-color: #165DFF;
  display: flex;
  height: 100px;
  padding: 20px 30px 10px 30px;
  position: relative;
  position: absolute;
  left: -30px;
  top: 0;
  border-radius: 0 0 200px 200px/0 0 40px 40px;
}
.home_info img {
  width: 58px;
  height: 58px;
  border-radius: 60px;
  margin-left: 30px;
  border: 2px solid #fff;
}
.home_info .info_box {
  padding-left: 14px;
}
.home_info .info_box .title {
  font-size: 18px;
  color: #fff;
  line-height: 20px;
  margin-top: 8px;
}
.home_info .info_box .con {
  margin-top: 8px;
  color: #FF7474;
  font-size: 12px;
  display: inline-block;
  background-color: rgb(255, 255, 255, 0.6);
  padding: 2px 5px 3px 5px;
  border-radius: 10px;
}
.home_info .info_box .van-icon {
  font-size: 14px;
  vertical-align: middle;
}
</style>
